<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>

    <v-card class="card-shadow border-radius-xl">
      <v-card-text class="card-padding">
        <!-- First Row -->
        <v-form ref="frm_vendors">
          <v-row>
            <v-col cols="12" md="3">
              <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                  </strong></span>Auction
                center</label>

              <v-autocomplete :items="auctionCenters" item-text="auction_center_name" item-value="id" hide-details="auto"
                v-model="auctionCenter" @change="clearOne" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-3
                  mb-0
                " outlined height="46" single-line :rules="[(v) => !!v || 'Auction center is required']"
                placeholder="Select auction center">
                <template v-slot:selection="{ item }">
                  <v-text label color="bg-default" class="py-1 px-2 my-0">
                    <span class="text-caption ls-0">{{
                      item.auction_center_name
                    }}</span>
                  </v-text>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                  </strong></span>Season</label>

              <v-autocomplete :items="seasons" item-value="id" hide-details="auto" v-model="season" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-3
                  mb-0
                " outlined height="46" @change="filterSeasonSale()" single-line
                :rules="[(v) => !!v || 'Season is required']" placeholder="Select Season">
                <template v-slot:selection="{ item }">
                  <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                    <span class="text-caption ls-0">{{ item.name }}</span>
                  </v-text>
                </template>
                <template v-slot:item="{ item }">
                  <v-text name="ss_name" label color="bg-default" class="py-1 px-2 my-0">
                    <span class="text-caption ls-0">{{ item.name }}</span>
                  </v-text>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                  </strong></span>Season
                sale</label>

              <v-autocomplete :items="seasonSales" item-value="id" item-text="season_sale" hide-details="auto"
                v-model="seasonSale" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-3
                  mb-0
                " outlined chips multiple height="46" single-line
                :rules="[(v) => v.length > 0 || 'Sale No is required']" placeholder="Select Sale No">
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                    <span class="text-white text-caption ls-0">{{
                      item.season_sale
                    }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ seasonSale.length - 1 }}
                    others)
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggleSalesNo">
                    <v-list-item-action>
                      <v-icon :color="seasonSales.length > 0 ? 'indigo darken-4' : ''">
                        {{ iconSale }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn elevation="0" :ripple="false" height="43" dark
                class="text-capitalize btn-ls btn-primary bg-success px-6 mt-9" @click="getAuctionVendors">Search
                Vendors</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <!-- Second Row -->
        <v-form ref="frm_po">
          <v-row v-if="showVendors">
            <v-col cols="12" md="5">
              <label class="text-md font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>* </strong></span>
                Vendor</label>
              <v-autocomplete :items="auction_vendors" item-value="id" item-text="name" v-model="auction_vendor" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                  mt-3
                " outlined chips multiple height="46" single-line placeholder="Select Vendor"
                :rules="[(v) => v.length > 0 || 'Vendor is required']">
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                    <span class="text-white text-caption ls-0">{{
                      item.name
                    }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ auction_vendor.length - 1 }}
                    others)
                  </span>
                </template>

                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggleVendor">
                    <v-list-item-action>
                      <v-icon :color="auction_vendor.length > 0 ? 'indigo darken-4' : ''
                        ">
                        {{ iconVendor }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" align-self="center">
              <v-btn elevation="0" :ripple="false" height="43" dark
                class="text-capitalize btn-ls btn-primary bg-success px-6 mt-2" @click="searchPoByVendors">Search
                PO</v-btn>
            </v-col>
          </v-row>
        </v-form>

        <!-- Third Row -->
        <v-form ref="frm_auction_search">
          <v-row v-if="showPo">
            <v-col cols="12" md="5">
              <label class="text-md font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>* </strong></span>

                PO Number</label>
              <v-autocomplete :items="auction_poNumbers" item-value="po_number" item-text="po_name"
                v-model="auction_poNumber" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                  mt-3
                " outlined chips multiple height="46" single-line placeholder="Select PO"
                :rules="[(v) => v.length > 0 || 'PO is required']">
                <template slot="v-list-tile-content" slot-scope="{ item }">
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.name }}
                      -
                      {{ item.po_number }}</v-list-tile-title>
                  </v-list-tile-content>
                </template>

                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                    <span class="text-white text-caption ls-0">
                      {{ item.name }}
                      -
                      {{ item.po_number }}
                    </span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ auction_poNumber.length - 1 }}
                    others)
                  </span>
                </template>

                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="togglePO">
                    <v-list-item-action>
                      <v-icon :color="auction_poNumber.length > 0 ? 'indigo darken-4' : ''
                        ">
                        {{ iconPo }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col> </v-row></v-form>
        <v-row>
          <v-col class="d-flex justify-end mx-5">
            <v-btn @click="clearfilter" elevation="0" :ripple="false" height="43" class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-light
                px-6
                mt-2
                mr-2
              ">Clear</v-btn>

            <v-btn elevation="0" :ripple="false" height="43" dark
              class="text-capitalize btn-ls btn-primary bg-success px-6 mt-2" @click="searchAuction">Search</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Data Table  -->
    <v-card class="card-shadow border-radius-xl mt-5" v-show="showTable" id="scroll_1">
      <v-card-text class="card-padding">
        <v-form ref="frm_table">
          <v-data-table v-model="selectedData" :headers="headers" :items="purchaseDetails" item-key="purchase_child_id"
            show-select mobile-breakpoint="0" fixed-header @toggle-select-all="allSelected" @item-selected="selectedSome"
            class="table" :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }">
            <!-- <template v-slot:[`item.prompt_date`]="{ item }">
              {{ formatDate(item.prompt_date) }}</template
            > -->
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->

                <v-spacer></v-spacer>

                <v-btn @click="showSelected" elevation="0" :ripple="false" height="43" dark class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    mb-5
                    mt-5
                  ">Show Selected List
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>

      <v-card-actions v-show="showTable">
        <v-row>
          <v-col offset-md="5">
            <v-btn @click="showSelected" elevation="0" :ripple="false" height="43" dark class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
                mb-5
                mt-5
              ">Show Selected List
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- Show Selected List -->
    <v-dialog v-model="showSelectedList" persistent fullscreen transition="dialog-bottom-transition">
      <v-card class="card-shadow border-radius-xl mt-5">
        <v-card-text class="card-padding">
          <v-form ref="frmAuction" v-if="purchaseType == 'AUCTION'">
            <!-- <v-card class="card-shadow border-radius-xl mt-5">
        <v-card-text class="card-padding"> -->
            <v-container class="px-0">
              <v-row><v-col cols="12" md="3">
                  <v-menu ref="mnu_date" v-model="mnu_date" :close-on-content-click="false" :return-value.sync="mnu_date"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <label class="text-sm font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>*
                          </strong></span>Dispatch Date</label><v-text-field v-model="date" readonly v-bind="attrs"
                        v-on="on" hide-details="auto" class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                          mt-5
                        " dense flat filled solo height="46" placeholder="Date" persistent-hint></v-text-field>
                    </template>
                    <v-date-picker color="green lighten-1" header-color="primary" v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="mnu_date = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.mnu_date.save(date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory">
                    Mode Of Transport</label>
                  <v-text-field v-model="mode_of_transport" hide-details="auto" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo height="43" placeholder="Enter mode of Transport"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory">
                    Vehicle No</label>
                  <v-text-field hide-details="auto" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo height="43" v-model="vehicle_no" placeholder="Enter Vehicle Number"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory">
                    Driver Contact No</label>
                  <v-text-field hide-details="auto" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo height="43" placeholder="Enter Conatct Number" v-model="driver_contact_no"
                    persistent-hint></v-text-field>
                </v-col>
              </v-row>
              <v-row> </v-row>
            </v-container>
            <!-- </v-card-text>
      </v-card> -->
          </v-form>
        </v-card-text>
        <v-card-text class="card-padding">
          <v-form ref="frm_table">
            <v-data-table v-model="saveList" :headers="headerSelected" :items="selectedData" item-key="purchase_child_id"
              show-select mobile-breakpoint="0" fixed-header class="table" @item-selected="selected"
              @toggle-select-all="selectedAll" :footer-props="{
                'items-per-page-options': [100, 200, 500, -1],
              }">
              <!-- <template v-slot:[`item.prompt_date`]="{ item }">
                {{ formatDate(item.prompt_date) }}</template
              > -->
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->

                  <v-spacer></v-spacer>
                  <v-btn @click="closeListDialogue" elevation="0" :ripple="false" height="43" dark class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mb-5
                      mt-5
                      mr-3
                    ">Go Back to Select More
                  </v-btn>
                  <v-btn @click="confirm" elevation="0" :ripple="false" height="43" dark class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mb-5
                      mt-5
                    ">Save
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.ordered_no_of_packages`]="{ item }">
                <div>
                  <v-text-field :disabled="item.available_package == 0" v-model.number="item.ordered_no_of_packages"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-1
                    " dense flat filled solo :rules="[
                      (v) => v >= 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.available_package ||
                        `Max should not be above ${item.available_package}`,
                    ]" min="0" @input="calculateRequiredQty(item)"></v-text-field>
                </div>
              </template><template v-slot:[`item.ordered_quantity`]="{ item }">
                <div>
                  <v-text-field :disabled="item.available_package == 0" v-model="item.ordered_quantity" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-1
                    " dense flat filled solo min="0" :rules="[
                      (v) => v >= 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.available_weight ||
                        `Max should not be above ${item.available_weight}`,
                    ]" @input="calculateRequiredPackage(item)"></v-text-field>
                </div>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>

        <v-card-actions v-show="showTable">
          <v-row>
            <v-col offset-md="5">
              <v-btn @click="confirm" elevation="0" :ripple="false" height="43" dark class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                  mb-5
                  mt-5
                ">Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialogue Remove Selected Confirm -->
    <v-dialog v-model="dialogueRemove" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">
          <p class="text-center">Do you want to Remove this item?</p>
        </v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="cancelRemove" elevation="0" :ripple="false" height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6">No</v-btn>

          <v-btn @click="confirmRemove" elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            ">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card> </v-dialog><!-- Dialogue Remove All Selected Confirm -->
    <v-dialog v-model="dialogueAllRemove" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">
          <p class="text-center">Do you want to Remove this item?</p>
        </v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="cancelAllRemove" elevation="0" :ripple="false" height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6">No</v-btn>

          <v-btn @click="confirmAllRemove" elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            ">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Save Dialogue -->
    <v-dialog v-model="dialogue" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">Do you want to Save?</v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="close" elevation="0" :ripple="false" height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6">No</v-btn>

          <v-btn @click="save" elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            ">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Show Batch No -->
    <v-dialog v-model="dialogBatchNo" persistent max-width="1200">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text>
          <v-data-table :headers="headersBatchno" :items="batchNo" mobile-breakpoint="0" :footer-props="{
            'items-per-page-options': [100, 200, 500, -1],
          }" fixed-header class="table" disable-pagination>
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->

                <v-spacer></v-spacer>
                <v-btn @click="closeBatchDialogue" text elevation="0" :ripple="false" height="43" color="red">close
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../api";
import apiMaster from "../../../master/master-get-api";
import formatDate from "../../../../global-data/dateFormatter";
export default {
  created() {
    this.initialize();
  },
  data() {
    return {
      purchaseType: "AUCTION",
      auctionCenters: [],
      auctionCenter: null,
      seasons: [],
      season: null,
      seasonSales: [],
      seasonSale: [],
      auction_vendor: [],
      auction_vendors: [],
      auction_poNumber: [],
      auction_poNumbers: [],
      overlay: false,
      // Show
      showVendors: false,
      showPo: false,

      // Delivery Details
      mode_of_transport: "",
      vehicle_no: "",
      driver_contact_no: "",
      // Data table Details
      selectedData: [],
      selectedDataList: [],
      purchaseDetails: [],
      showTable: false,
      headers: [
        {
          text: "Lot",
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: true,
        },
        {
          text: "Garden",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: true,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: true,
        },

        {
          text: "Invoice",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 120,
        },

        {
          text: "Item",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
        },
        {
          text: "Available (Bags)",
          value: "available_package",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Net",
          value: "unit_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Available (Kgs)",
          value: "available_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },

        {
          text: "purchased rate",
          value: "purchased_rate",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
      ],

      header2: [
        {
          text: "Required Bags",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Required Quantity",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
      ],

      //Selected List
      headerSelected: [],
      showSelectedList: false,
      saveList: [],

      //Remove from selected list
      dialogueRemove: false,
      removeObj: null,
      dialogueAllRemove: false,

      //Disptch Date
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,

      // Saving
      dialogue: false,

      //Data Table Batch
      dialogBatchNo: false,
      batchNo: [],
      headersBatchno: [
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Mark",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Invoice",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 250,
        },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return formatDate.formatDate(date);
    },
    toggleSalesNo() {
      this.$nextTick(() => {
        if (this.selectAllSale) {
          this.seasonSale = [];
        } else {
          this.seasonSale = this.seasonSales.slice().map((item) => {
            return item.id;
          });
        }
      });
    },
    toggleVendor() {
      this.$nextTick(() => {
        if (this.selectAllVendor) {
          this.auction_vendor = [];
        } else {
          this.auction_vendor = this.auction_vendors.slice().map((item) => {
            return item.id;
          });
        }
      });
    },
    togglePO() {
      this.$nextTick(() => {
        if (this.selectAllPo) {
          this.auction_poNumber = [];
        } else {
          this.auction_poNumber = this.auction_poNumbers.slice().map((item) => {
            return item.po_number;
          });
        }
      });
    },
    async initialize() {
      this.overlay = true;

      this.auctionCenters = [];
      this.auctionCenter = null;
      this.seasons = [];
      this.season = null;
      this.seasonSales = [];
      this.seasonSale = [];
      this.auction_vendor = [];
      this.auction_vendors = [];
      this.auction_poNumber = [];
      this.auction_poNumbers = [];
      this.overlay = false;

      this.showVendors = false;
      this.showPo = false;

      this.mode_of_transport = "";
      this.vehicle_no = "";
      this.driver_contact_no = "";

      this.selectedData = [];
      this.selectedDataList = [];
      this.purchaseDetails = [];
      this.showTable = false;
      this.headerSelected = [];
      this.showSelectedList = false;
      this.saveList = [];

      this.dialogueRemove = false;
      this.removeObj = null;
      this.dialogueAllRemove = false;

      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.mnu_date = false;

      this.dialogue = false;

      this.auctionCenters = await apiMaster.getAuctionCenter();

      this.seasons = await apiMaster.getSeason();
      this.overlay = false;
    },
    async filterSeasonSale() {
      if (this.auctionCenter) {
        this.overlay = true;
        this.clearTwo();
        this.seasonSale = [];
        this.seasonSales =
          await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
            this.season,
            this.auctionCenter
          );
        this.overlay = false;
      }
    },
    getAuctionVendorsValidate() {
      return this.$refs.frm_vendors.validate();
    },

    async getAuctionVendors() {
      if (this.getAuctionVendorsValidate()) {
        this.auction_vendor = [];
        this.auction_vendors = [];
        this.overlay = true;
        this.auction_vendors = await api.getAuctionVenders(this.seasonSale);
        if (this.auction_vendors.length > 0) {
          this.showVendors = true;
        } else {
          this.showNoDataAlert("No Vendor Found...Please Try Another Sale");
          this.showVendors = false;
          this.showPo = false;
        }

        this.overlay = false;
      }
    },
    searchPoByVendorsValidate() {
      return this.$refs.frm_po.validate();
    },

    async searchPoByVendors() {
      if (this.searchPoByVendorsValidate()) {
        this.auction_poNumbers = [];
        this.auction_poNumber = [];

        this.overlay = true;
        let params = {
          vendors: this.auction_vendor,
          season_sales: this.seasonSale,
        };

        this.auction_poNumbers = await api.getPoByVendorsAndSeasonSale(params);
        if (this.auction_poNumbers.length > 0) {
          this.showPo = true;
        } else {
          this.showPo = false;
          this.showNoDataAlert(
            " No Open PO found this Vendor...Please Try Another Vendor"
          );
        }

        this.overlay = false;
      }
    },
    clearOne() {
      this.auction_vendor = [];
      this.auction_poNumber = [];
      this.season = null;
      this.showTable = false;
      this.showSelectedList = false;

      this.seasonSale = [];

      this.purchaseDetails = [];
      this.selectedData = [];
    },
    clearTwo() {
      this.auction_vendor = [];
      this.auction_poNumber = [];

      this.seasonSale = [];

      this.purchaseDetails = [];
      this.selectedData = [];
    },
    clearfilter() {
      this.auction_vendor = [];
      this.auction_poNumber = [];
      this.season = null;
      this.auctionCenter = null;
      this.seasonSale = [];
      this.$refs.frm_vendors.resetValidation();
      this.$refs.frm_po.resetValidation();
      this.$refs.frm_auction_search.resetValidation();
      this.purchaseDetails = [];
      this.selectedData = [];
    },
    frmAuctionSearchValidate() {
      return this.$refs.frm_auction_search.validate();
    },

    async searchAuction() {
      if (this.frmAuctionSearchValidate()) {
        if (this.auction_poNumber.length > 0) {
          try {
            this.overlay = true;
            this.selectedData = [];
            this.purchaseDetails = [];
            let params = {
              po_numbers: this.auction_poNumber,
              purchase_type: this.purchaseType,
            };
            let result =
              await api.getPurchaseDetailsByPoNumbersForAuctionDispatch(params);
            this.overlay = false;
            this.purchaseDetails = result;
            if (this.purchaseDetails.length > 0) {
              this.showTable = true;
              this.scrollDown();
            } else {
              this.showNoDataAlert("No Data found...");
            }
            console.log("this.result", result);
          } catch (error) {
            console.log(error);
          }
        } else {
          this.showWarningAlert("Please Select PO Numbers");
        }
      }
    },

    async allSelected(obj) {
      this.overlay = true;
      // console.log("SELECT ALL");
      if (obj.value == true) {
        this.selectedData = [];
        for await (const pd of this.purchaseDetails) {
          console.log("pd", pd);
          pd.ordered_no_of_packages = pd.available_package;
          pd.ordered_quantity = pd.available_weight;
          this.selectedData.push(pd);
        }
      }
      if (obj.value == false) {
        for await (const pd of this.purchaseDetails) {
          pd.ordered_no_of_packages = 0;
          this.selectedData = [];
        }
      }
      this.overlay = false;
    },
    async selectedSome(obj) {
      let po = obj.item.po_number;
      let id = obj.item.purchase_child_id;
      console.log(po);
      if (obj.value == true) {
        for await (const pd of this.purchaseDetails) {
          if (pd.po_number == po) {
            console.log("pdsome", pd);
            pd.ordered_no_of_packages = pd.available_package;
            pd.ordered_quantity = pd.available_weight;
            this.selectedData.push(pd);
          }
        }
      }
      if (obj.value == false) {
        let r = this.selectedData.filter((el) => el.po_number != po);
        let i = this.purchaseDetails.findIndex((el) => el.po_number == po);
        this.purchaseDetails[i].ordered_no_of_packages = 0;
        console.log(r);
        this.selectedData = r;
      }
      let uniqueChars = [...new Set(this.selectedData)];
      this.selectedData = [...uniqueChars];
      console.log(this.selectedData);
    },
    auctionValidate() {
      return this.$refs.frmAuction.validate();
    },
    tableValidation() {
      return this.$refs.frm_table.validate();
    },

    showSelected() {
      if (this.selectedData.length > 0) {
        this.saveList = [];

        this.headerSelected = this.headers.concat(this.header2);
        this.showSelectedList = true;
        this.selectedData.forEach((el) => {
          el.ordered_no_of_packages = el.available_package;
        });
        this.saveList = this.selectedData;
      } else {
        this.showNoDataAlert("Select Atleast one row");
      }
    },
    closeListDialogue() {
      this.showSelectedList = false;
    },
    //Single remove
    async selected(obj) {
      if (obj.value == false) {
        this.removeObj = null;
        this.dialogueRemove = true;
        this.removeObj = obj;
      }
    },
    confirmRemove() {
      let idx = this.selectedData.findIndex((el) => {
        return el == this.removeObj.item;
      });
      this.selectedData.splice(idx, 1);
      this.dialogueRemove = false;
      if (this.selectedData.length == 0) this.showSelectedList = false;
    },
    cancelRemove() {
      this.saveList = this.selectedData;
      this.dialogueRemove = false;
    },

    // AllRemove

    selectedAll(obj) {
      if (obj.value == false) {
        this.dialogueAllRemove = true;
      }
    },
    cancelAllRemove() {
      this.saveList = this.selectedData;
      this.dialogueAllRemove = false;
    },
    confirmAllRemove() {
      this.dialogueAllRemove = false;
      this.saveList = [];
      this.selectedData = [];
      this.showSelectedList = false;
    },

    calculateRequiredQty(item) {
      if ((item.ordered_no_of_packages * item.unit_weight) > item.available_weight) {
        item.ordered_quantity = item.available_weight;
      } else {
        item.ordered_quantity = item.ordered_no_of_packages * item.unit_weight;
      }
      this.$forceUpdate();
    },
    calculateRequiredPackage(item) {
      item.ordered_no_of_packages = Math.ceil(item.ordered_quantity / item.unit_weight);
      this.$forceUpdate();
    },

    confirm() {
      if (this.tableValidation()) {
        if (this.purchaseType == "AUCTION") {
          if (this.auctionValidate()) {
            if (this.selectedData.length > 0) this.dialogue = true;
            else {
              this.showWarningAlert("Select Atleast One Row...");
            }
          }
        }
      }
    },
    close() {
      this.dialogue = false;
    },
    async save() {
      let req = null;
      this.dialogue = false;
      console.log(this.purchaseType);
      // ## Saving Auction Type
      if (this.purchaseType == "AUCTION") {
        this.overlay = true;
        req = {
          auction_center: this.auctionCenter, //Only for Auction for gen dispatch Code
          season: this.season, //Only for Auction for gen dispatch Code

          mode_of_transport: this.mode_of_transport,
          vehicle_no: this.vehicle_no,
          driver_contact_no: this.driver_contact_no,
          dispatchChild: this.saveList,
          dispatch_date: this.date,
          purchase_master_id: this.saveList[0].purchase_master_id,
          purchase_type: "AUCTION",
        };
        try {
          console.log("Auction Api Calling ...");
          let result = await api.createDispatch(req);
          if (result.length > 0) {
            this.showSelectedList = false;
            this.dialogBatchNo = true;
            this.initialize();
            this.batchNo = result;
          } else {
            this.showNoDataAlert(
              "No Data Found(Order quantity Should Greater than 0)"
            );
          }

          // this.showSuccessAlert("Dispatch Created");
          // this.purchaseDetails = [];
          // this.selectedData = [];
          // this.$refs.frmAuction.reset();
        } catch (error) {
          console.log(error);
          this.showErrorAlert(error);
        } finally {
          this.overlay = false;
          // this.selectedData = [];
          // this.purchaseDetails = [];
          // this.showTable = false;
        }
        // }
      }
    },

    showNoDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    closeBatchDialogue() {
      this.dialogBatchNo = false;
      this.batchNo = [];
    },
    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },
  },
  computed: {
    selectAllSale() {
      return this.seasonSale.length == this.seasonSales.length;
    },
    selectSomeSale() {
      return this.seasonSale.length > 0 && !this.selectAllSale;
    },

    iconSale() {
      if (this.selectAllSale) return "ni-fat-remove text-lg";
      if (this.selectSomeSale) return "ni-fat-delete";
      return "fa-square-o";
    },
    //
    selectAllVendor() {
      return this.auction_vendor.length == this.auction_vendors.length;
    },
    selectSomeVendor() {
      return this.auction_vendor.length > 0 && !this.selectAllVendor;
    },

    iconVendor() {
      if (this.selectAllVendor) return "ni-fat-remove text-lg";
      if (this.selectSomeVendor) return "ni-fat-delete";
      return "fa-square-o";
    },
    //
    selectAllPo() {
      return this.auction_poNumber.length == this.auction_poNumbers.length;
    },
    selectSomePo() {
      return this.auction_poNumber.length > 0 && !this.selectAllPo;
    },

    iconPo() {
      if (this.selectAllPo) return "ni-fat-remove text-lg";
      if (this.selectSomePo) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>

<style></style>
