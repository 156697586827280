<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-form ref="frmFilter">
              <v-row class="mt-8">
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span> Company
                    Warehouse</label
                  >
                  <v-autocomplete
                    v-model="compnay_warehouse"
                    :items="compnay_warehouses"
                    item-text="name"
                    item-value="id"
                    return-object
                    color="rgba(0,0,0,.6)"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    placeholder="Select a Vendor"
                    :rules="[(v) => !!v || 'Vendor is Required']"
                    outlined
                    single-line
                    height="48"
                    @change="getPo"
                  >
                  </v-autocomplete> </v-col
                ><v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>

                    Po Number</label
                  >
                  <v-autocomplete
                    v-model="poNumber"
                    :items="poNumbers"
                    item-text="po_number"
                    item-value="id"
                    return-object
                    color="rgba(0,0,0,.6)"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-5
                      mb-0
                    "
                    placeholder="Select a PO Number"
                    :rules="[(v) => !!v || 'PO number is Required']"
                    outlined
                    single-line
                    height="48"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end ma-5">
                  <v-btn
                    @click="clearfilter"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-secondary
                      bg-light
                      py-3
                      px-6
                      mr-2
                    "
                    >Clear</v-btn
                  >

                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                    "
                    @click="search"
                    >Search</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>

      <v-form ref="frmAuction">
        <v-card class="card-shadow border-radius-xl mt-5">
          <v-card-text class="card-padding">
            <v-container class="px-0">
              <v-row>
                <v-col cols="12" md="4">
                  <label class="text-md font-weight-bolder ms-1 mandatory">
                    Mode Of Transport</label
                  >
                  <v-text-field
                    v-model="mode_of_transport"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="Enter mode of Transport"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md font-weight-bolder ms-1 mandatory">
                    Vehicle No</label
                  >
                  <v-text-field
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="vehicle_no"
                    placeholder="Enter Vehicle Number"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md font-weight-bolder ms-1 mandatory">
                    Driver Contact No</label
                  >
                  <v-text-field
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="Enter Conatct Number"
                    v-model="driver_contact_no"
                    :rules="[
                      (v) => !!v || 'Conatct Number is required',
                      (v) => v.length == 10 || 'Conatct Number Should 10 digit',
                    ]"
                    persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" offset-md="10">
                  <v-btn
                    @click="clear"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-secondary
                      bg-light
                      py-3
                      px-6
                      mt-12
                      mr-2
                    "
                    >Clear</v-btn
                  ></v-col
                >
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
      <v-form ref="frmTable" v-show="showTable">
        <v-card class="card-shadow border-radius-xl mt-5">
          <v-card-text class="card-padding">
            <v-data-table
              v-model="selectedData"
              :headers="headers"
              :items="purchaseDetails"
              item-key="purchase_child_id"
              show-select
              mobile-breakpoint="0"
              :footer-props="{
                'items-per-page-options': [100, 200, 500, -1],
              }"
              fixed-header
              class="table"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->

                  <v-spacer></v-spacer>
                  <v-btn
                    @click="confirm"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mb-5
                      mt-5
                    "
                    >Save
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.ordered_no_of_packages`]="{ item }">
                <div>
                  <v-text-field
                    v-model.number="item.ordered_no_of_packages"
                    :disabled="item.available_package == 0"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-1
                    "
                    dense
                    flat
                    filled
                    solo
                    :rules="[
                      (v) => v >= 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.available_package ||
                        `Max should not be above ${item.available_package}`,
                    ]"
                    min="0"
                  ></v-text-field>
                </div> </template
              ><template v-slot:[`item.ordered_quantity`]="{ item }">
                <div>
                  <v-text-field
                    v-model="item.ordered_quantity"
                    v-on="
                      (item.ordered_quantity =
                        item.ordered_no_of_packages * item.unit_weight)
                    "
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-1
                    "
                    dense
                    flat
                    filled
                    solo
                    disabled
                    min="0"
                    :rules="[
                      (v) => v >= 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.available_package * item.unit_weight ||
                        `Max should not be above ${
                          item.available_package * item.unit_weight
                        }`,
                    ]"
                  ></v-text-field>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col offset-md="5">
                <v-btn
                  @click="confirm"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    mb-5
                    mt-5
                  "
                  >Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
    <!-- Save Dialogue -->
    <v-dialog v-model="dialogue" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Do you want to Save?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
            >No</v-btn
          >

          <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            "
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Show Batch No -->
    <v-dialog v-model="dialogBatchNo" persistent max-width="1200">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text>
          <v-data-table
            :headers="headersBatchno"
            :items="batchNo"
            mobile-breakpoint="0"
            hide-default-footer
            fixed-header
            class="table"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->

                <v-spacer></v-spacer>
                <v-btn
                  @click="closeBatchDialogue"
                  text
                  elevation="0"
                  :ripple="false"
                  height="43"
                  color="red"
                  >close
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../api";
export default {
  data() {
    return {
      dialogue: false,
      showTable: false,
      compnay_warehouse: null,
      compnay_warehouses: [],
      wareHouses: [],
      wareHouse: null,
      saleTypes: [],
      poNumbers: [],
      poNumber: null,
      saleType: "",
      status: "",
      overlay: false,
      purchaseDetails: [],
      selectedData: [],
      mode_of_transport: "",
      vehicle_no: "",
      driver_contact_no: "",
      status: "", //pending, confirm, recieved

      vendorType: "OWN",
      type: "INTERNAL TRANSFER",
      headers: [
        {
          text: "Invoice NO",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Mark",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Mark",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Unit Weight",
          value: "unit_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Available No: Of Packages",
          value: "available_package",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Order No: of Package",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Order Quantity",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
      ],
      //
      dialogBatchNo: false,
      batchNo: [],
      headersBatchno: [
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Mark",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Invoice",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 250,
        },

        // {
        //   text: "Unit Weight",
        //   value: "unit_weight",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "center",
        //   width: 150,
        //   sortable: false,
        // },
        // {
        //   text: "Order No: of Package",
        //   value: "ordered_no_of_packages",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "center",
        //   width: 150,
        //   sortable: false,
        // },
        // {
        //   text: "Order Quantity",
        //   value: "ordered_quantity",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "center",
        //   width: 150,
        //   sortable: false,
        // },
      ],
    };
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.overlay = true;
      this.compnay_warehouses = await api.getCompanyWareHouse();
      this.overlay = false;
      // this.wareHouses = await api.getCompanyWareHouse();
    },
    async getVendor() {
      this.selectedData = [];
      this.purchaseDetails = [];
      this.compnay_warehouses = [];
      this.compnay_warehouse = null;
    },
    clearVendor() {
      this.compnay_warehouse = null;
      this.poNumber = null;
      this.poNumbers = [];
      this.selectedData = [];
      this.purchaseDetails = [];
    },
    async getPo() {
      this.selectedData = [];
      this.purchaseDetails = [];
      this.poNumbers = [];
      this.poNumber = null;
      try {
        this.poNumbers = await api.getPo(this.compnay_warehouse.id);
        console.log(this.poNumbers);
      } catch (error) {}
    },
    async search() {
      if (this.validateFrmFilter()) {
        try {
          this.selectedData = [];
          this.purchaseDetails = [];
          let result = await api.getPurchaseDeatils(
            this.poNumber.po_number,
            this.type
          );
          this.purchaseDetails = result;
          if (this.purchaseDetails.length > 0) {
            this.showTable = true;
          } else {
            this.nodDataAlert("No Data found");
          }
        } catch (error) {
          console.log(error);
        }
      } else {
      }
    },
    auctionValidate() {
      return this.$refs.frmAuction.validate();
    },
    validateFrmFilter() {
      return this.$refs.frmFilter.validate();
    },

    confirm() {
      if (this.auctionValidate()) {
        if (this.selectedData.length > 0) {
          this.dialogue = true;
        } else {
          this.showWarningAlert("Select Atleast One Row");
        }
      }
    },
    close() {
      this.dialogue = false;
    },
    clearfilter() {
      this.compnay_warehouse = null;
      this.poNumbers = [];
      this.poNumber = null;
      this.selectedData = [];
      this.purchaseDetails = [];
      this.this.$refs.frmFilter.resetValidation();
    },
    clear() {
      this.mode_of_transport = "";
      this.vehicle_no = "";
      this.driver_contact_no = "";
      this.$refs.frmAuction.resetValidation();
    },
    async save() {
      this.overlay = true;
      let req = null;
      this.dialogue = false;

      req = {
        po_number: this.poNumber.po_number,
        mode_of_transport: this.mode_of_transport,
        vehicle_no: this.vehicle_no,
        driver_contact_no: this.driver_contact_no,
        dispatchChild: this.selectedData,
        purchase_master_id: this.selectedData[0].purchase_master_id,
        purchase_type: this.type,
        company_warehouse: this.compnay_warehouse,
      };

      try {
        // console.log("Auction Api Calling ...");

        this.overlay = false;
        console.log(req);
        let result = await api.createDispatch(req);
        this.dialogBatchNo = true;
        this.batchNo = result;
        this.purchaseDetails = [];
        this.selectedData = [];
        this.$refs.frmAuction.reset();
      } catch (error) {
        console.log(error);
        this.showErrorAlert("Odoo Server Error");
      } finally {
        this.overlay = false;
        this.selectedData = [];
        this.purchaseDetails = [];
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    nodDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    closeBatchDialogue() {
      this.dialogBatchNo = false;
      this.batchNo = [];
    },
  },
};
</script>

<style></style>
