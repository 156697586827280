<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>

    <v-card class="card-shadow border-radius-xl">
      <v-card-text class="card-padding">
        <v-form ref="frm_search">
          <v-row>
            <v-col cols="12" md="3">
              <v-menu ref="mnu_date" v-model="mnu_date" :close-on-content-click="false" :return-value.sync="mnu_date"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <label class="text-sm font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>*
                      </strong></span>Dispatch
                    Date</label><v-text-field v-model="date" readonly v-bind="attrs" v-on="on" hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo height="46" placeholder="Date" persistent-hint></v-text-field>
                </template>
                <v-date-picker color="green lighten-1" header-color="primary" v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnu_date = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.mnu_date.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <label class="text-md font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>*
                  </strong></span>
                Vendor</label>
              <v-autocomplete v-model="vendor" :items="vendors" item-text="name" item-value="id" return-object
                color="rgba(0,0,0,.6)" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mb-0
                  mt-5
                " placeholder="Select a Vendor" :rules="[(v) => !!v || 'Vendor is Required']" outlined single-line
                height="48" @change="getPo">
              </v-autocomplete> </v-col><v-col cols="12" md="3">
              <label class="text-md font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>*
                  </strong></span>

                Po Number</label>
              <v-autocomplete v-model="poNumber" :items="poNumbers" item-text="po_number" item-value="id" return-object
                color="rgba(0,0,0,.6)" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-5
                  mb-0
                " placeholder="Select a PO Number" :rules="[(v) => !!v || 'PO number is Required']" outlined
                single-line height="48">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col class="d-flex justify-end mx-5">
            <v-btn @click="clearfilter" elevation="0" :ripple="false" height="43" class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-light
                px-6
                mt-2
                mr-2
              ">Clear</v-btn>

            <v-btn elevation="0" :ripple="false" height="43" dark
              class="text-capitalize btn-ls btn-primary bg-success px-6 mt-2" @click="search">Search</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="card-shadow border-radius-xl mt-5" v-show="showTable" id="scroll_1">
      <v-card-text class="card-padding">
        <!-- Data Table -->
        <v-form ref="frm_table">
          <v-data-table v-model="selectedData" :headers="headers" :items="purchaseDetails" item-key="purchase_child_id"
            show-select mobile-breakpoint="0" fixed-header class="table" :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }">
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->

                <v-row class="mb-5">
                  <v-col cols="3"> </v-col>
                  <v-col offset="6" cols="3">
                    <v-btn @click="showSelected" elevation="0" :ripple="false" height="43" dark class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        mb-5
                        mt-5
                      ">Show Selected
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.ordered_no_of_packages`]="{ item }">
              <div>
                <v-text-field :disabled="item.available_package == 0" v-model.number="item.ordered_no_of_packages"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-1
                  " dense flat filled solo :rules="[
                    (v) => v >= 0 || 'Min should be above 0',
                    (v) =>
                      v <= item.available_package ||
                      `Max should not be above ${item.available_package}`,
                  ]" min="0"></v-text-field>
              </div>
            </template><template v-slot:[`item.ordered_quantity`]="{ item }">
              <div>
                <v-text-field :disabled="item.available_package == 0" v-model="item.ordered_quantity" v-on="(item.ordered_quantity =
                  item.ordered_no_of_packages * item.unit_weight >
                    item.available_weight
                    ? item.available_weight
                    : item.ordered_no_of_packages * item.unit_weight)
                  " class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-1
                  " dense flat filled solo min="0" :rules="[
                    (v) => v >= 0 || 'Min should be above 0',
                    (v) =>
                      v <= item.available_weight ||
                      `Max should not be above ${item.available_weight}`,
                  ]"></v-text-field>
              </div>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Save Dialogue -->
    <v-dialog v-model="dialogue" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">Do you want to Save?</v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="close" elevation="0" :ripple="false" height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6">No</v-btn>

          <v-btn @click="checkConfirm" elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            ">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Show Batch No -->
    <v-dialog v-model="dialogBatchNo" persistent max-width="1200">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text>
          <v-data-table :headers="headersBatchno" :items="batchNo" mobile-breakpoint="0" fixed-header class="table"
            :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }">
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->

                <v-spacer></v-spacer>
                <v-btn @click="closeBatchDialogue" text elevation="0" :ripple="false" height="43" color="red">close
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmDispatch" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">Do you want to Confirm Dispatch?</v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="closeDialogConfirmDispatch" elevation="0" :ripple="false" height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6">No</v-btn>

          <v-btn @click="confirmDispatch" elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            ">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Selected List -->
    <v-dialog v-model="showSelectedList" persistent fullscreen transition="dialog-bottom-transition">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-form>
            <!-- <v-card class="card-shadow border-radius-xl mt-5">
        <v-card-text class="card-padding"> -->
            <v-container class="px-0">
              <v-row>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory">
                    Mode Of Transport</label>
                  <v-text-field v-model="mode_of_transport" hide-details="auto" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo height="43" placeholder="Enter mode of Transport"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory">
                    Vehicle No</label>
                  <v-text-field hide-details="auto" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo height="43" v-model="vehicle_no" placeholder="Enter Vehicle Number"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory">
                    Driver Contact No</label>
                  <v-text-field hide-details="auto" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo height="43" placeholder="Enter Conatct Number" v-model="driver_contact_no"
                    persistent-hint></v-text-field>
                </v-col>
              </v-row>
              <v-row> </v-row>
            </v-container>
            <!-- </v-card-text>
      </v-card> -->
          </v-form>
        </v-card-text>
        <v-card-title>
          <v-spacer></v-spacer><v-btn @click="closeListDialogue" elevation="0" :ripple="false" height="43" dark class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
              mb-5
              mt-5
              mr-2
            ">Go Back to Select More
          </v-btn>
          <v-btn @click="confirm" elevation="0" :ripple="false" height="43" dark class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
              mb-5
              mt-5
            ">Save
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="frm_save_table">
            <v-data-table v-model="saveList" :headers="selectedHeader" :items="selectedData" item-key="id"
              mobile-breakpoint="0" fixed-header show-select class="table" disable-pagination
              :hide-default-footer="true" @item-selected="selectOne" @toggle-select-all="allListSelected">
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>Selected</v-toolbar-title> -->
                </v-toolbar>
              </template>
              <template v-slot:[`item.ordered_no_of_packages`]="{ item }">
                <div>
                  <v-text-field :disabled="item.available_package == 0" v-model.number="item.ordered_no_of_packages"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-1
                    " dense flat filled solo :rules="[
                      (v) => v >= 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.available_package ||
                        `Max should not be above ${item.available_package}`,
                    ]" min="0"></v-text-field>
                </div>
              </template><template v-slot:[`item.ordered_quantity`]="{ item }">
                <div>
                  <v-text-field :disabled="item.available_package == 0" v-model="item.ordered_quantity"
                    v-on="updateOrderedQuantity(item)" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-1
                    " dense flat filled solo min="0" :rules="[
                      (v) => v >= 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.available_weight ||
                        `Max should not be above ${item.available_weight}`,
                    ]"></v-text-field>
                </div>
              </template> </v-data-table></v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col offset-md="5">
              <v-btn @click="confirm" elevation="0" :ripple="false" height="43" dark class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                  mb-5
                  mt-5
                ">Save
              </v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialogue Remove Selected Confirm -->
    <v-dialog v-model="dialogueRemove" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">
          <p class="text-center">Do you want to Remove this item?</p>
        </v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="cancelRemove" elevation="0" :ripple="false" height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6">No</v-btn>

          <v-btn @click="confirmRemove" elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            ">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialogue Remove SelectedAll Confirm -->
    <v-dialog v-model="dialogueAllRemove" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">
          <p class="text-center">Do you want to Remove All item?</p>
        </v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="cancelAllRemove" elevation="0" :ripple="false" height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6">No</v-btn>

          <v-btn @click="confirmAllRemove" elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            ">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../api";
import apiMaster from "../../../master/master-get-api";
export default {
  created() {
    this.initialize();
  },
  data() {
    return {
      overlay: false,
      purchaseType: "PRIVATE",
      vendor: null,
      vendors: [],
      wareHouses: [],
      wareHouse: null,
      poNumbers: [],
      poNumber: null,
      selectedData: [],
      purchaseDetails: [],
      showTable: false,
      confirm_status: false,
      saveList: [],
      selectedList: [],
      // Saving
      dialogue: false,
      // Data Table detaisl
      dialogConfirmDispatch: false,
      showSelectedList: false,
      dialogueRemove: false,
      removeObj: null,
      headers: [
        {
          text: "Garden",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
        },

        {
          text: "Invoice NO",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
        },

        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
        },

        {
          text: "Available (Bags)",
          value: "available_package",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Net Kgs",
          value: "unit_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Available (Kgs)",
          value: "available_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },

        // {
        //   text: "Total Orderd (Packages)",
        //   value: "total_odered",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   width: 150,
        //   sortable: false,
        // },
        // {
        //   text: "Pending (Packages)",
        //   value: "pending_order",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   width: 150,
        //   sortable: false,
        // },

        // {
        //   text: "Recieved (Packages)",
        //   value: "recieved_order",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   width: 150,
        //   sortable: false,
        // },

        //
      ],
      childHeader: [
        {
          text: "Required Bags",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Required Qty",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
      ],

      selectedHeader: [],

      //Data Table Batch
      dialogBatchNo: false,
      batchNo: [],
      headersBatchno: [
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Mark",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Invoice",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
        },

        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 250,
        },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
      // All remove
      dialogueAllRemove: false,
      //
      mode_of_transport: "",
      vehicle_no: "",
      driver_contact_no: "",
    };
  },

  methods: {
    async initialize() {
      this.vendors = [];
      this.vendor = null;
      this.mode_of_transport = "";
      this.vehicle_no = "";
      this.driver_contact_no = "";
      this.vendors = await api.getVendorsByType(this.purchaseType);
    },
    async getPo() {
      this.selectedData = [];
      this.purchaseDetails = [];
      this.poNumbers = [];
      this.poNumber = null;
      try {
        let req = {
          vendor: this.vendor.id,
          type: this.purchaseType,
        };

        this.poNumbers = await api.getPo(req);
        // console.log(this.poNumbers);
      } catch (error) { }
    },
    searchValidate() {
      return this.$refs.frm_search.validate();
    },

    tableValidation() {
      return this.$refs.frm_save_table.validate();
    },
    async search() {
      try {
        if (this.searchValidate()) {
          this.overlay = true;
          this.selectedData = [];
          this.purchaseDetails = [];
          let result = await api.getPurchaseDeatils(
            this.poNumber.po_number,
            this.purchaseType
          );
          this.overlay = false;
          this.purchaseDetails = result;
          if (this.purchaseDetails.length > 0) {
            this.showTable = true;
            this.scrollDown();
          } else {
            this.showNoDataAlert("No Data found...");
          }
          console.log("this.result", result);
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkConfirm() {
      this.dialogue = false;
      this.dialogConfirmDispatch = true;
    },
    closeDialogConfirmDispatch() {
      this.confirm_status = false;
      this.dialogConfirmDispatch = false;
      this.save();
    },
    confirmDispatch() {
      console.log("this.tableValidation()", this.tableValidation());
      if (this.tableValidation()) {
        this.dialogConfirmDispatch = false;
        this.confirm_status = true;
        this.save();
      }
    },
    closeListDialogue() {
      this.showSelectedList = false;
    },
    showSelected() {
      if (this.selectedData.length > 0) {
        this.selectedHeader = this.headers.concat(this.childHeader);
        this.saveList = this.selectedData;
        this.showSelectedList = true;
      } else {
        this.showWarningAlert("Select Atleast One Row...");
      }
    },
    selectOne(obj) {
      this.dialogueRemove = true;
      this.removeObj = obj;
    },

    updateOrderedQuantity(item) {
      if (item.ordered_no_of_packages === item.available_package) {  // when available_weight is above the calculated weight
        item.ordered_quantity = item.available_weight;
      } else {                                                       // when available_weight is below the calculated weight
        item.ordered_quantity = Math.min(
          item.ordered_no_of_packages * item.unit_weight,
          item.available_weight
        );
      }
    },

    cancelRemove() {
      this.removeObj = null;
      this.dialogueRemove = false;
      this.saveList = this.selectedData;
    },
    confirmRemove() {
      let r = this.selectedData.findIndex((el) => {
        return el == this.removeObj.item;
      });
      this.selectedData.splice(r, 1);

      if (this.selectedData.length == 0) {
        this.showSelectedList = false;
      }
      this.removeObj = null;
      this.dialogueRemove = false;
    },

    // Select all
    allListSelected() {
      this.dialogueAllRemove = true;
    },
    cancelAllRemove() {
      this.dialogueAllRemove = false;

      this.saveList = this.selectedData;
    },
    confirmAllRemove() {
      this.dialogueAllRemove = false;

      this.selectedData = [];
      this.saveList = [];
      this.showSelectedList = false;
    },
    async save() {
      let req = null;
      this.dialogue = false;

      if (this.purchaseType == "PRIVATE") {
        this.showSelectedList = false;
        this.overlay = true;
        req = {
          po_number: this.poNumber.po_number,
          purchase_master_id: this.selectedData[0].purchase_master_id,
          dispatchChild: this.selectedData,
          purchase_type: "PRIVATE",
          vendor: this.vendor,
          mode_of_transport: this.mode_of_transport,
          vehicle_no: this.vehicle_no,
          driver_contact_no: this.driver_contact_no,

          confirm_status: this.confirm_status,
          dispatch_date: this.date,
        };
        try {
          // console.log("Private Api Calling ...", req);
          let result = await api.createDispatch(req);
          this.batchNo = result;

          // this.showSuccessAlert("Dispatch Created");
          this.purchaseDetails = [];
          this.selectedData = [];
          this.mode_of_transport = "";
          this.vehicle_no = "";
          this.driver_contact_no = "";
          this.showTable = false;

          this.showSuccessAlert("Data Saved...");
        } catch (error) {
          console.log(error);
          this.showErrorAlert(error);
        } finally {
          this.overlay = false;
          this.selectedData = [];
          this.purchaseDetails = [];
          this.saveList = [];
          this.selectedList = [];
        }
      }
    },

    clearfilter() {
      this.vendor = null;
      this.poNumber = null;
      this.selectedData = [];
      this.purchaseDetails = [];
      this.showTable = false;
    },
    close() {
      this.dialogue = false;
      this.batchNo = [];
    },

    confirm() {
      // console.log("tableValidation", this.tableValidation());
      if (this.tableValidation()) {
        if (this.selectedData.length > 0) {
          this.dialogue = true;
        } else {
          this.showWarningAlert("Select Atleast One Row...");
        }
      }
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showNoDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    closeBatchDialogue() {
      this.dialogBatchNo = false;
      this.batchNo = [];
    },
    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },
  },
};
</script>

<style></style>
