<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-form ref="frmFilter">
            <v-row class="mt-2">
              <v-col cols="12" md="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  >Purchase Type</label
                >
                <v-form ref="frmType">
                  <v-select
                    @change="getUi"
                    v-model="purchaseType"
                    :items="purchaseTypes"
                    item-text="type"
                    item-value="type"
                    color="rgba(0,0,0,.6)"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    placeholder="Select a Purchase Type"
                    :rules="[(v) => !!v || 'Purchase Type is Required']"
                    outlined
                    single-line
                    height="48"
                  >
                  </v-select>
                </v-form>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <AuctionDispatch v-if="showAuction" class="mt-5"></AuctionDispatch>
      <PrivateDispatch v-if="showPrivate" class="mt-5"></PrivateDispatch>
      <InternalTransfer
        v-if="showInternalTransfer"
        class="mt-5"
      ></InternalTransfer>
    </v-container>
  </div>
</template>

<script>
import AuctionDispatch from "./auction-dispatch.vue";
import PrivateDispatch from "./private-disptch.vue";
import InternalTransfer from "../../../internal-transfer/internal-dispatch/views/internal-dispatch.vue";

export default {
  components: {
    AuctionDispatch,
    PrivateDispatch,
    InternalTransfer,
  },
  data() {
    return {
      overlay: false,
      // Auction
      showAuction: false,
      showPrivate: false,
      showInternalTransfer: false,

      purchaseTypes: [
        {
          type: "AUCTION",
        },
        { type: "PRIVATE" },
        // { type: "INTERNAL TRANSFER" },
      ],
      purchaseType: "",
    };
  },
  methods: {
    getUi() {
      if (this.purchaseType == "AUCTION") {
        this.showPrivate = false;
        this.showAuction = true;
        this.showInternalTransfer = false;
      } else if (this.purchaseType == "PRIVATE") {
        this.showPrivate = true;
        this.showAuction = false;
        this.showInternalTransfer = false;
      } else if (this.purchaseType == "INTERNAL TRANSFER") {
        this.showPrivate = false;
        this.showAuction = false;
        this.showInternalTransfer = true;
      }
    },
  },
};
</script>

<style></style>
